import { useState } from "react";
import { useDispatch } from "react-redux";
import { TextInput, Button, Label } from "flowbite-react";
import { atualizarUsuarioAction } from "../../actions/user";

const UserForm = ({ profile }) => {
  const dispatch = useDispatch();

  // Estado para o formulário do usuário
  const [userForm, setUserForm] = useState({
    nome: profile?.nome || "",
    email: profile?.email || "",
    telefone: profile?.telefone || "",
    cpf: profile?.cpf || "", // CPF mascarado
  });

  // Controla se o campo CPF pode ser editado
  const [isEditingCpf, setIsEditingCpf] = useState(false);

  // Estado para mensagens de erro
  const [cpfError, setCpfError] = useState("");

  // Validação de CPF
  const validateCpf = (cpf) => {
    cpf = cpf.replace(/\D/g, ""); // Remove caracteres não numéricos

    // Verifica se o CPF tem exatamente 11 dígitos
    if (cpf.length !== 11) return false;

    // Verifica se todos os dígitos são iguais (ex: "111.111.111-11")
    if (/^(\d)\1{10}$/.test(cpf)) return false;

    // Cálculo do primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let firstDigit = (sum * 10) % 11;
    if (firstDigit === 10 || firstDigit === 11) firstDigit = 0;

    // Cálculo do segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    let secondDigit = (sum * 10) % 11;
    if (secondDigit === 10 || secondDigit === 11) secondDigit = 0;

    // Verifica se os dígitos calculados conferem com os últimos do CPF
    return (
      firstDigit === parseInt(cpf.charAt(9)) &&
      secondDigit === parseInt(cpf.charAt(10))
    );
  };

  // Atualiza os campos do formulário do usuário
  const handleUserChange = (e) => {
    const { name, value } = e.target;

    if (name === "cpf") {
      const sanitizedCpf = value.replace(/\D/g, ""); // Remove caracteres não numéricos
      setUserForm({ ...userForm, cpf: sanitizedCpf });

      // Valida o CPF e atualiza o estado de erro
      if (sanitizedCpf.length !== 11) {
        setCpfError("O CPF deve conter 11 dígitos.");
      } else if (!validateCpf(sanitizedCpf)) {
        setCpfError("CPF inválido.");
      } else {
        setCpfError(""); // Limpa o erro se o CPF for válido
      }
    } else {
      setUserForm({ ...userForm, [name]: value });
    }
  };

  // Envia os dados para o backend
  const handleSubmitUser = (e) => {
    e.preventDefault();

    // Impede o envio se houver erro no CPF
    if (cpfError) {
      alert("Corrija os erros antes de salvar.");
      return;
    }

    // Prepara os dados para envio, incluindo o CPF apenas se foi editado
    const { cpf, ...rest } = userForm; // Remove o CPF temporariamente
    const dataToSend = {
      ...rest,
      ...(isEditingCpf && { cpf }), // Adiciona o CPF apenas se foi editado
    };

    dispatch(atualizarUsuarioAction(dataToSend));
  };

  return (
    <form className="mt-10 space-y-8" onSubmit={handleSubmitUser}>
      <h2 className="text-2xl font-semibold text-gray-700">
        Informações Pessoais
      </h2>
      <div>
        <Label htmlFor="nome" className="mb-2 block">
          Nome
        </Label>
        <TextInput
          id="nome"
          name="nome"
          type="text"
          placeholder="Seu nome completo"
          value={userForm.nome}
          onChange={handleUserChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="email" className="mb-2 block">
          Email
        </Label>
        <TextInput
          id="email"
          name="email"
          type="email"
          placeholder="seu@email.com"
          value={userForm.email}
          onChange={handleUserChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="telefone" className="mb-2 block">
          Telefone
        </Label>
        <TextInput
          id="telefone"
          name="telefone"
          type="text"
          placeholder="(99) 99999-9999"
          value={userForm.telefone}
          onChange={handleUserChange}
        />
      </div>
      <div>
        <Label htmlFor="cpf" className="mb-2 block">
          CPF
        </Label>
        <div className="flex items-center space-x-2">
          <TextInput
            id="cpf"
            name="cpf"
            type="text"
            placeholder="123.456.789-00"
            value={userForm.cpf}
            onChange={handleUserChange}
            disabled={!isEditingCpf} // Desabilita o campo se não estiver em modo de edição
            className="flex-1"
          />
          {!isEditingCpf && (
            <Button
              type="button"
              gradientDuoTone="purpleToPink"
              size="sm"
              onClick={() => setIsEditingCpf(true)}
            >
              Alterar CPF
            </Button>
          )}
        </div>
        {cpfError && <p className="text-red-500 text-sm mt-2">{cpfError}</p>}
      </div>
      <div>
        <Button
          type="submit"
          gradientDuoTone="purpleToPink"
          size="lg"
          className="w-full"
        >
          Salvar Informações Pessoais
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
