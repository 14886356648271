import React, { useState, useEffect } from "react";
import { TextInput, Button, Label } from "flowbite-react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../actions/user";

const CadastraUsuario = () => {
  const [searchParams] = useSearchParams(); // Captura os parâmetros da query string
  const roleFromQuery = searchParams.get("role"); // Obtém o valor de 'role' da query string
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Hook para despachar ações
  const {
    loading,
    error: registerError,
    user,
  } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: roleFromQuery || "lojista", // Usa o valor da query string ou define "lojista" como padrão
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (formData.password !== formData.confirmPassword) {
      setError("As senhas não coincidem.");
      console.log("Erro: As senhas não coincidem.");
      return;
    }

    if (!validatePassword(formData.password)) {
      setError(
        "A senha deve ter pelo menos 6 caracteres, incluindo letras e números."
      );
      console.log("Erro: Senha inválida.");
      return;
    }

    try {
      await dispatch(registerUser(formData, navigate));
    } catch (error) {
      console.error("Erro ao registrar usuário:", error.message);
      setError(error.message); // Exibe a mensagem de erro recebida
    }
  };

  return (
    <div className="relative isolate bg-white px-6 lg:px-8">
      {/* Background decorativo */}
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>

      {/* Formulário de Cadastro */}
      <div className="mx-auto max-w-lg py-16 sm:py-20 lg:py-24">
        <h1 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
          Crie sua Conta
        </h1>
        <p className="mt-4 text-center text-gray-600">
          Preencha os dados abaixo para começar a usar a plataforma Beauty 2B.
        </p>

        <form className="mt-10 space-y-6" onSubmit={handleSubmit}>
          {/* Nome */}
          <div>
            <Label htmlFor="nome" className="mb-2 block">
              Nome
            </Label>
            <TextInput
              id="nome"
              name="nome"
              type="text"
              placeholder="Seu nome completo"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email */}
          <div>
            <Label htmlFor="email" className="mb-2 block">
              Email
            </Label>
            <TextInput
              id="email"
              name="email"
              type="email"
              placeholder="seu@email.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Senha */}
          <div>
            <Label htmlFor="password" className="mb-2 block">
              Senha
            </Label>
            <TextInput
              id="password"
              name="password"
              type="password"
              placeholder="Escolha uma senha segura"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          {/* Confirmar Senha */}
          <div>
            <Label htmlFor="confirmPassword" className="mb-2 block">
              Confirmar Senha
            </Label>
            <TextInput
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Digite a senha novamente"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>

          {/* Exibir Erro */}
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {registerError && (
            <p className="text-red-500 text-sm">{registerError}</p>
          )}

          {/* Botão de Cadastro */}
          <div>
            <Button
              type="submit"
              gradientDuoTone="purpleToPink"
              size="lg"
              className="w-full"
            >
              Cadastrar
            </Button>
          </div>
        </form>
        {/* Termos e Cadastro */}
        <div className="mt-6 space-y-4 text-center text-sm text-gray-600">
          {/* Termos de Uso */}
          <p>
            Ao fazer se cadastrar, você concorda com nossos{" "}
            <Link
              to="/termos"
              className="font-medium text-indigo-600 hover:text-indigo-800"
            >
              Termos de Uso
            </Link>{" "}
            e{" "}
            <Link
              to="/politicas"
              className="font-medium text-indigo-600 hover:text-indigo-800"
            >
              Políticas de Privacidade
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default CadastraUsuario;
