import React, { useState } from "react";
import { Button } from "flowbite-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { criarFornecedorAction } from "../../../actions/user";
import AvatarSection from "../EditFornecedor/AvatarSection";
import FormFields from "../EditFornecedor/FormFields";
import ItemListManager from "../EditFornecedor/ItemListManager";

const CreateFornecedor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    nome_fantasia: "",
    cpf_cnpj: "",
    email_contato: "",
    telefone: "",
    whatsapp: "",
    categorias: [],
    marcas: [],
    nacional: false,
    profile_picture: null,
    estados: [],
    relacao_com_empresa: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validações
    if (!formData.nome_fantasia.trim()) {
      return alert("O campo Nome Fantasia é obrigatório.");
    }

    if (!formData.cpf_cnpj.trim()) {
      return alert("O campo CPF/CNPJ é obrigatório.");
    }

    if (!formData.email_contato.trim()) {
      return alert("O campo Email de Contato é obrigatório.");
    }

    if (!formData.telefone.trim()) {
      return alert("O campo Telefone é obrigatório.");
    }

    if (
      !formData.nacional &&
      (!formData.estados || formData.estados.length === 0)
    ) {
      return alert("Pelo menos um estado deve ser selecionado.");
    }

    if (!formData.categorias || formData.categorias.length === 0) {
      return alert("Pelo menos uma Categoria deve ser adicionada.");
    }

    if (!formData.marcas || formData.marcas.length === 0) {
      return alert("Pelo menos uma Marca deve ser adicionada.");
    }

    const dataToSubmit = {
      ...formData,
      categorias: JSON.stringify(formData.categorias),
      marcas: JSON.stringify(formData.marcas),
      estados: JSON.stringify(formData.estados),
    };

    dispatch(criarFornecedorAction(dataToSubmit))
      .then(() => {
        navigate("/app/supplier"); // Redireciona após sucesso
      })
      .catch((error) => {
        console.error("Erro ao criar fornecedor:", error);
        alert("Erro ao cadastrar fornecedor. Tente novamente.");
      });
  };

  return (
    <form className="mt-10 space-y-8" onSubmit={handleSubmit}>
      <h2 className="text-2xl font-semibold text-gray-700">
        Cadastrar Nova Empresa
      </h2>

      <AvatarSection formData={formData} setFormData={setFormData} />
      <FormFields formData={formData} setFormData={setFormData} />

      <ItemListManager
        label="Categorias"
        placeholder="Adicione uma categoria"
        items={formData.categorias}
        setItems={(items) => setFormData({ ...formData, categorias: items })}
      />
      <ItemListManager
        label="Marcas"
        placeholder="Adicione uma marca"
        items={formData.marcas}
        setItems={(items) => setFormData({ ...formData, marcas: items })}
      />

      <Button
        type="submit"
        gradientDuoTone="purpleToPink"
        size="lg"
        className="w-full mt-6"
      >
        Cadastrar Empresa
      </Button>
    </form>
  );
};

export default CreateFornecedor;
