import React from "react";
import { Sidebar } from "flowbite-react";
import {
  HiOutlineLogout,
  HiHome,
  HiUserCircle,
  HiBriefcase,
  HiCreditCard,
} from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";

const SidebarComponent = ({ isSidebarVisible, toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Obtém a role do usuário do estado
  const userRole = useSelector((state) => state.user?.profile?.role);

  const handleLogout = () => {
    navigate("/");
    toggleSidebar(); // Fecha a sidebar ao fazer logout
    dispatch(logout()); // Despacha a ação de logout
  };

  // Função para fechar a sidebar após a navegação
  const handleCloseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div
      className={`fixed top-0 h-full left-0 bg-white shadow-md transition-transform duration-300 z-40 ${
        isSidebarVisible ? "translate-x-0" : "-translate-x-full"
      } lg:relative lg:translate-x-0 lg:fixed lg:w-64`}
    >
      <Sidebar
        aria-label="Main navigation sidebar"
        className="h-full lg:mt-0 mt-12 "
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {userRole === "lojista" && (
            <Sidebar.Item as={Link} to="/app/home" icon={HiHome} onClick={handleCloseSidebar}>
              Home
            </Sidebar.Item>
            )}
            <Sidebar.Item as={Link} to="/app/user" icon={HiUserCircle} onClick={handleCloseSidebar}>
              Perfil
            </Sidebar.Item>

            {userRole === "fornecedor" && (
              <Sidebar.Item as={Link} to="/app/empresas" icon={HiBriefcase} onClick={handleCloseSidebar}>
                Empresas
              </Sidebar.Item>
            )}

            {userRole === "lojista" && (
              <Sidebar.Item as={Link} to="/app/planos" icon={HiCreditCard} onClick={handleCloseSidebar}>
                Plano
              </Sidebar.Item>
            )}

            <Sidebar.Item as={Link} onClick={handleLogout} icon={HiOutlineLogout}>
              Logout
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
};

export default SidebarComponent;
