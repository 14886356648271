import React from "react";
import { Modal, Button, Avatar } from "flowbite-react";
import {
  HiOutlineMail,
  HiOutlinePhone,
  HiTruck,
  HiOutlineLocationMarker,
  HiOutlineClipboardList,
  HiOutlineTag,
} from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";

const FornecedorModal = ({ fornecedor, onClose }) => {
  if (!fornecedor) return null;

  // Formatar endereço
  const formatarEndereco = () => {
    const { rua, numero, complemento, bairro, cidade, estado, cep } =
      fornecedor;
    if (!rua || !cidade || !estado) return "Endereço não informado.";

    let endereco = `${rua}, ${numero || "S/N"}`;
    if (complemento) endereco += `, ${complemento}`;
    if (bairro) endereco += `, ${bairro}`;
    endereco += ` - ${cidade}, ${estado}`;
    if (cep) endereco += `, CEP: ${cep}`;
    return endereco;
  };

  // Link do WhatsApp com mensagem personalizada
  const whatsappNumber = fornecedor.whatsapp?.replace(/\D/g, ""); // Remove caracteres não numéricos
  const whatsappMessage = encodeURIComponent(
    "Olá, encontrei sua empresa no Beauty2B e gostaria de saber mais sobre seus produtos e serviços."
  );
  const whatsappLink = whatsappNumber
    ? `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${whatsappMessage}`
    : null;

  return (
    <Modal show={Boolean(fornecedor)} onClose={onClose} size="lg">
      <Modal.Header>
        <div className="flex items-center space-x-4">
          <Avatar
            img={
              fornecedor.profile_picture
                ? `https://api.beauty2b.com.br${fornecedor.profile_picture}`
                : "/supplier.png"
            }
            alt={`Foto de perfil do fornecedor ${fornecedor.nome_fantasia}`}
            rounded
            size="lg"
            className="w-18 h-18"
          />
          <h3 className="text-xl font-bold">
            {fornecedor.nome_fantasia || "Fornecedor sem nome"}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          {/* Email */}
          {fornecedor.email_contato && (
            <p className="flex items-center gap-2 text-gray-800">
              <HiOutlineMail className="text-lg" />
              <b>Email:</b> {fornecedor.email_contato}
            </p>
          )}

          {/* Telefone */}
          {fornecedor.telefone && (
            <p className="flex items-center gap-2 text-gray-800">
              <HiOutlinePhone className="text-lg" />
              <b>Telefone:</b> {fornecedor.telefone}
            </p>
          )}

          {/* Estados Atendidos */}
          <p className="flex items-center gap-2 text-gray-800">
            <HiTruck className="text-lg" />
            <b>Estados Atendidos:</b>{" "}
            {fornecedor.nacional
              ? "Todo o Brasil"
              : fornecedor.estados?.join(", ") || "Não informado"}
          </p>

          {/* Categorias */}
          {fornecedor.categorias?.length > 0 && (
            <p className="flex items-center gap-2 text-gray-800">
              <HiOutlineClipboardList className="text-lg" />
              <b>Categorias:</b> {fornecedor.categorias.join(", ")}
            </p>
          )}

          {/* Marcas */}
          {fornecedor.marcas?.length > 0 && (
            <p className="flex items-center gap-2 text-gray-800">
              <HiOutlineTag className="text-lg" />
              <b>Marcas:</b> {fornecedor.marcas.join(", ")}
            </p>
          )}

          {/* Endereço */}
          <p className="flex items-center gap-2 text-gray-800">
            <HiOutlineLocationMarker className="text-lg" />
            <b>Endereço:</b> {formatarEndereco()}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* Botão de WhatsApp */}
        {whatsappLink && (
          <Button
            gradientDuoTone="purpleToPink"
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-auto"
          >
            <FaWhatsapp className="h-5 w-5 mr-2" />
            WhatsApp
          </Button>
        )}

        {/* Botão Fechar */}
        <Button gradientDuoTone="purpleToPink" onClick={onClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FornecedorModal;
