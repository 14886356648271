import axios from "axios";
axios.defaults.withCredentials = true;

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL);

export function login(email, password) {
  return axios
    .post(`${API_URL}/auth/login`, { email, password })
    .catch((error) => {
      const errorMessage =
      error.response?.data?.message || "Erro ao se comunicar com o servidor.";
      throw new Error(errorMessage); // Lança um erro com uma mensagem mais clara
    });
}

export function logout() {
  return axios
    .post(`${API_URL}/auth/logout`)
    .catch((error) => {
      const errorMessage =
      error.response?.data?.message || "Erro ao se comunicar com o servidor.";
      throw new Error(errorMessage); // Lança um erro com uma mensagem mais clara
    });
}

export function checkAuthStatus() {
  return axios
    .get(`${API_URL}/auth/status`)
    .catch((error) => {
      const errorMessage =
      error.response?.data?.message || "Erro ao verificar o status de autenticação.";
      throw new Error(errorMessage); // Lança um erro com uma mensagem mais clara
    });
}
