import React, { useState } from "react";
import { TextInput, Label, Checkbox, Button, Badge } from "flowbite-react";

const estadosBrasileiros = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const FormFields = ({ formData, setFormData }) => {
  const [estadoSelecionado, setEstadoSelecionado] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Aplica máscara ao campo de CEP
  const handleCepChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const maskedValue = rawValue.replace(/^(\d{5})(\d{3})$/, "$1-$2"); // Aplica a máscara 00000-000
    setFormData({
      ...formData,
      cep: maskedValue,
    });
  };

  const handleAddEstado = () => {
    if (!estadoSelecionado) return;

    if (!(formData.estados || []).includes(estadoSelecionado)) {
      setFormData({
        ...formData,
        estados: [...(formData.estados || []), estadoSelecionado],
      });
      setEstadoSelecionado("");
    }
  };

  const handleRemoveEstado = (estado) => {
    setFormData({
      ...formData,
      estados: (formData.estados || []).filter((e) => e !== estado),
    });
  };

  return (
    <>
      <div>
        <Label htmlFor="nome_fantasia" className="mb-2 block">
          Nome Fantasia
        </Label>
        <TextInput
          id="nome_fantasia"
          name="nome_fantasia"
          type="text"
          placeholder="Nome do fornecedor"
          value={formData.nome_fantasia}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="cpf_cnpj" className="mb-2 block">
          CNPJ
        </Label>
        <TextInput
          id="cpf_cnpj"
          name="cpf_cnpj"
          type="text"
          placeholder="CNPJ"
          value={formData.cpf_cnpj}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="email_contato" className="mb-2 block">
          Email de Contato
        </Label>
        <TextInput
          id="email_contato"
          name="email_contato"
          type="email"
          placeholder="Email do fornecedor"
          value={formData.email_contato}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="relacao_com_empresa" className="mb-2 block">
          Sua Relação com a Empresa
        </Label>
        <TextInput
          id="relacao_com_empresa"
          name="relacao_com_empresa"
          type="text"
          placeholder="Ex.: Proprietário, Gerente, Representante"
          value={formData.relacao_com_empresa}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="telefone" className="mb-2 block">
          Telefone
        </Label>
        <TextInput
          id="telefone"
          name="telefone"
          type="text"
          placeholder="Telefone do fornecedor"
          value={formData.telefone}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="whatsapp" className="mb-2 block">
          WhatsApp
        </Label>
        <TextInput
          id="whatsapp"
          name="whatsapp"
          type="text"
          placeholder="Número do WhatsApp"
          value={formData.whatsapp}
          onChange={handleChange}
        />
      </div>

      {/* Seção de Endereço */}
      <div className="border rounded-lg p-4 mt-6">
        <h3 className="text-lg font-semibold mb-4">Endereço</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <Label htmlFor="logradouro" className="mb-2 block">
              Logradouro
            </Label>
            <TextInput
              id="logradouro"
              name="logradouro"
              type="text"
              placeholder="Rua, Avenida, etc."
              value={formData.logradouro}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="numero" className="mb-2 block">
              Número
            </Label>
            <TextInput
              id="numero"
              name="numero"
              type="text"
              placeholder="Número"
              value={formData.numero}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="complemento" className="mb-2 block">
              Complemento
            </Label>
            <TextInput
              id="complemento"
              name="complemento"
              type="text"
              placeholder="Sala, Apartamento, etc."
              value={formData.complemento}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="bairro" className="mb-2 block">
              Bairro
            </Label>
            <TextInput
              id="bairro"
              name="bairro"
              type="text"
              placeholder="Bairro"
              value={formData.bairro}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="cidade" className="mb-2 block">
              Cidade
            </Label>
            <TextInput
              id="cidade"
              name="cidade"
              type="text"
              placeholder="Cidade"
              value={formData.cidade}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="estado" className="mb-2 block">
              Estado
            </Label>
            <select
              id="estado"
              name="estado"
              className="border border-gray-300 rounded-lg px-3 py-2 w-full"
              value={formData.estado}
              onChange={handleChange}
            >
              <option value="">Selecione o estado</option>
              {estadosBrasileiros.map((estado) => (
                <option key={estado} value={estado}>
                  {estado}
                </option>
              ))}
            </select>
          </div>
          <div>
            <Label htmlFor="cep" className="mb-2 block">
              CEP
            </Label>
            <TextInput
              id="cep"
              name="cep"
              type="text"
              placeholder="00000-000"
              value={formData.cep}
              onChange={handleCepChange} // Usa a função para aplicar a máscara
            />
          </div>
        </div>
      </div>

      {/* Configuração da Cobertura Regional */}
      <div className="border rounded-lg p-4 mt-6">
        <h3 className="text-lg font-semibold mb-4">
          Qual a região é atendida por este fornecedor?
        </h3>

        <div className="flex items-center mb-4">
          <Checkbox
            id="nacional"
            name="nacional"
            checked={formData.nacional}
            onChange={handleChange}
          />
          <Label htmlFor="nacional" className="ml-2">
            Todos os estados do Brasil
          </Label>
        </div>

        {!formData.nacional && (
          <>
            <div>
              <div className="flex items-center gap-2">
                <select
                  id="estados"
                  className="border border-gray-300 rounded-lg px-3 py-2"
                  value={estadoSelecionado}
                  onChange={(e) => setEstadoSelecionado(e.target.value)}
                >
                  <option value="">Selecione um estado</option>
                  {estadosBrasileiros.map((estado) => (
                    <option key={estado} value={estado}>
                      {estado}
                    </option>
                  ))}
                </select>
                <Button
                  gradientDuoTone="purpleToPink"
                  type="button"
                  onClick={handleAddEstado}
                >
                  Adicionar
                </Button>
              </div>
            </div>

            {formData.estados?.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-4">
                {formData.estados.map((estado) => (
                  <Badge
                    key={estado}
                    className="flex items-center bg-purple-500 text-white"
                  >
                    {estado}
                    <button
                      type="button"
                      onClick={() => handleRemoveEstado(estado)}
                      className="ml-2"
                    >
                      ✕
                    </button>
                  </Badge>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FormFields;
