import React, { useState } from "react";
import { Avatar, Button } from "flowbite-react";
import CropImage from "./CropImage";

const AvatarSection = ({ formData, setFormData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditingPhoto, setIsEditingPhoto] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width === img.height) {
          // Se a imagem for quadrada, use diretamente
          setFormData({ ...formData, profile_picture: file });
          setIsEditingPhoto(false);
        } else {
          setSelectedFile(img.src);
          setIsEditingPhoto(true);
        }
      };
      setError("");
    } else {
      setError("Por favor, selecione um arquivo de imagem válido.");
    }
  };

  const handleCropSave = async (blob) => {
    setFormData({ ...formData, profile_picture: blob });
    setIsEditingPhoto(false);
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <Avatar
        img={
          formData.profile_picture instanceof Blob
            ? URL.createObjectURL(formData.profile_picture)
            : formData.profile_picture
            ? `https://api.beauty2b.com.br${formData.profile_picture}`
            : "/supplier.png"
        }
        alt="Foto de perfil do fornecedor"
        rounded
        size="xl"
      />
      <Button
        gradientDuoTone="purpleToPink"
        type="button"
        onClick={() => document.getElementById("file-input").click()}
      >
        Editar Foto de Perfil
      </Button>
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}
      {isEditingPhoto && (
        <CropImage
          selectedFile={selectedFile}
          formData={formData}
          setFormData={setFormData}
          setIsEditingPhoto={setIsEditingPhoto}
          onCropSave={handleCropSave}
        />
      )}
    </div>
  );
};

export default AvatarSection;
