import React, { useState } from "react";
import { Card, Avatar, Button, Select, TextInput, Modal } from "flowbite-react";
import {
  HiOutlineMail,
  HiOutlineTag,
  HiOutlineClipboardList,
  HiEye,
  HiOutlineSearch,
  HiOutlineFilter,
} from "react-icons/hi";
import { buscarFornecedoresPorEstado } from "../../api/buscarFornecedores";
import FornecedorModal from "./FornecedorModal";

const normalizeText = (text) => {
  return (text || "") // Garante que text é uma string
    .toString() // Converte o valor para string, caso necessário
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, ""); // Remove acentos e espaços extras
};

const ListaFornecedores = () => {
  const [fornecedores, setFornecedores] = useState([]);
  const [erro, setErro] = useState(null);
  const [estado, setEstado] = useState("");
  const [marcaFiltro, setMarcaFiltro] = useState("");
  const [categoriaFiltro, setCategoriaFiltro] = useState("");
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [modalFornecedor, setModalFornecedor] = useState(null);

  const handleBuscar = () => {
    if (!estado) {
      setErro("Por favor, selecione um estado para realizar a busca.");
      return;
    }

    buscarFornecedoresPorEstado(estado)
      .then((response) => {
        setFornecedores(response.data);
        setErro(null);
      })
      .catch((error) => {
        setFornecedores([]);
        const errorMessage =
          error.response?.data?.error ||
          "Erro ao buscar fornecedores. Tente novamente mais tarde.";
        setErro(errorMessage);
        console.error(error);
      });
  };

  const filteredFornecedores = fornecedores.filter((fornecedor) => {
    const normalizedCategorias = normalizeText(
      Array.isArray(fornecedor.categorias)
        ? fornecedor.categorias.join(", ")
        : ""
    );
    const normalizedMarcas = normalizeText(
      Array.isArray(fornecedor.marcas) ? fornecedor.marcas.join(", ") : ""
    );
    const normalizedMarcaFiltro = normalizeText(marcaFiltro);
    const normalizedCategoriaFiltro = normalizeText(categoriaFiltro);

    return (
      (!marcaFiltro || normalizedMarcas.includes(normalizedMarcaFiltro)) &&
      (!categoriaFiltro ||
        normalizedCategorias.includes(normalizedCategoriaFiltro))
    );
  });

  const handleOpenModal = (fornecedor) => {
    setModalFornecedor(fornecedor);
  };

  const handleCloseModal = () => {
    setModalFornecedor(null);
  };

  return (
    <div className="flex flex-col items-center w-full max-w-6xl mx-auto mt-10 px-4">
      <div className="flex flex-wrap md:flex-nowrap gap-4 mb-6 w-full max-w-md">
        {/* Select do Estado */}
        <Select
          id="estado"
          required
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          className="w-full md:w-3/5"
        >
          <option value="">Selecione um estado</option>
          <option value="SP">São Paulo</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="MG">Minas Gerais</option>
          <option value="DF">Distrito Federal</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="PR">Paraná</option>
          <option value="BA">Bahia</option>
          <option value="SC">Santa Catarina</option>
          <option value="GO">Goiás</option>
          <option value="PE">Pernambuco</option>
          <option value="CE">Ceará</option>
          <option value="PA">Pará</option>
          <option value="MT">Mato Grosso</option>
          <option value="ES">Espírito Santo</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="AM">Amazonas</option>
          <option value="MA">Maranhão</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="PB">Paraíba</option>
          <option value="AL">Alagoas</option>
          <option value="PI">Piauí</option>
          <option value="SE">Sergipe</option>
          <option value="RO">Rondônia</option>
          <option value="TO">Tocantins</option>
          <option value="AC">Acre</option>
          <option value="AP">Amapá</option>
          <option value="RR">Roraima</option>
        </Select>

        {/* Button Group */}
        <div className="flex gap-2 w-full md:w-2/5">
          <Button
            gradientDuoTone="purpleToPink"
            size="sm"
            onClick={handleBuscar}
            className="flex items-center gap-2 w-full"
          >
            <HiOutlineSearch className="h-5 w-5 mr-3" />
            Buscar
          </Button>

          <Button
            gradientDuoTone="purpleToPink"
            size="sm"
            onClick={() => setMostrarFiltros(!mostrarFiltros)}
            className="flex items-center gap-2 w-full"
          >
            <HiOutlineFilter className="h-5 w-5 mr-3" />
            {mostrarFiltros ? "Esconder" : "Filtrar"}
          </Button>
        </div>
      </div>

      {/* Filtros Adicionais */}
      {mostrarFiltros && (
        <div className="w-full flex flex-col md:flex-row gap-4 mb-6 max-w-md">
          <TextInput
            placeholder="Filtrar por marca"
            value={marcaFiltro}
            onChange={(e) => setMarcaFiltro(e.target.value)}
            className="w-full"
          />
          <TextInput
            placeholder="Filtrar por categorias"
            value={categoriaFiltro}
            onChange={(e) => setCategoriaFiltro(e.target.value)}
            className="w-full"
          />
        </div>
      )}

      {erro && <p className="text-red-500 text-center mb-4">{erro}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
        {filteredFornecedores.length > 0
          ? filteredFornecedores.map((fornecedor) => (
              <Card
                key={fornecedor.id}
                className="flex flex-col justify-between h-full w-full"
              >
                <div className="flex items-center space-x-4">
                  {/* Avatar à esquerda */}
                  <div className="flex-shrink-0">
                    <Avatar
                      img={
                        fornecedor.profile_picture
                          ? `https://api.beauty2b.com.br${fornecedor.profile_picture}`
                          : "/supplier.png" // Imagem padrão
                      }
                      alt={`Foto de perfil do fornecedor ${fornecedor.nome_fantasia}`}
                      rounded
                      size="lg"
                      className="w-18 h-18"
                    />
                  </div>

                  {/* Informações do fornecedor à direita */}
                  <div className="flex flex-col flex-grow max-w-full overflow-hidden">
                    <h3 className="text-base font-semibold text-gray-900">
                      {fornecedor.nome_fantasia || "Fornecedor sem nome"}
                    </h3>
                    <p
                      className="text-sm text-gray-600 truncate"
                      title={fornecedor.categorias?.join(", ")}
                    >
                      {fornecedor.categorias?.join(", ")}
                    </p>
                  </div>
                </div>
                {/* Botão "Visualizar" */}
                <div className="flex justify-end">
                  <Button
                    gradientDuoTone="purpleToPink"
                    onClick={() => handleOpenModal(fornecedor)}
                  >
                    <HiEye className="h-5 w-5 mr-2" />
                    Visualizar
                  </Button>
                </div>
              </Card>
            ))
          : null}
      </div>
      <FornecedorModal fornecedor={modalFornecedor} onClose={handleCloseModal} />
    </div>
  );
};

export default ListaFornecedores;
