import React from "react";
import { Card, Button, Avatar, Tooltip } from "flowbite-react";
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { HiPencilSquare } from "react-icons/hi2";

const Fornecedores = () => {
  const navigate = useNavigate();

  const fornecedores = useSelector(
    (state) => state.user.profile?.fornecedores || []
  );

  const handleAddFornecedor = () => {
    window.location.href = "/app/empresas/adicionar";
  };

  return (
    <div className="container mx-auto mt-10 relative px-4">
      {" "}
      {/* Adiciona padding horizontal */}
      <h1 className="text-2xl font-bold mb-6">Gerenciar Empresas</h1>
      {fornecedores.length === 0 && (
        <div className="text-center py-10">
          <p className="text-lg text-gray-600">
            Nenhum fornecedor cadastrado. Clique no botão{" "}
            <span className="rounded-full text-white px-1 bg-purple-500 ">
              <b>+</b>
            </span>{" "}
            abaixo para adicionar.
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {fornecedores.map((fornecedor) => (
          <Card key={fornecedor.id} className="max-w-sm">
            <div className="flex items-center space-x-2">
              {/* Avatar com tamanho fixo */}
              <div className="flex-shrink-0">
                <Avatar
                  img={
                    fornecedor.profile_picture
                      ? `https://api.beauty2b.com.br${fornecedor.profile_picture}`
                      : "/supplier.png" // Imagem padrão
                  }
                  alt={`Foto de perfil do fornecedor ${fornecedor.nome_fantasia}`}
                  rounded
                  size="lg"
                  className="w-20 h-20" // Tamanho fixo
                />
              </div>

              {/* Informações do fornecedor */}
              <div className="flex-auto max-w-full overflow-hidden">
                <h3 className="text-base sm:text-lg font-semibold break-words">
                  {fornecedor.nome_fantasia || "Fornecedor sem nome"}
                </h3>
                <p className="text-xs sm:text-sm text-gray-600 break-words">
                  {fornecedor.email_contato || "Sem email de contato"}
                </p>
                <p className="text-xs sm:text-sm text-gray-600 break-words">
                  {fornecedor.telefone || "Sem telefone"}
                </p>
              </div>
            </div>

            {/* Botão para editar */}
            <div className="mt-4">
              <Button
                gradientDuoTone="purpleToPink"
                onClick={() => {
                  navigate(`/app/empresas/edit/${fornecedor.id}`);
                }}
              >
                <HiPencilSquare className=" h-4 w-6" />
                Editar Empresa
              </Button>
            </div>
          </Card>
        ))}
      </div>
      {/* Floating Action Button com Tooltip */}
      <div className="fixed bottom-6 right-6">
        <Tooltip content="Cadastrar novo fornecedor" placement="left">
          <Button
            gradientDuoTone="purpleToPink"
            className="rounded-full py-6 px-4 shadow-lg"
            onClick={handleAddFornecedor}
          >
            <FaPlus className="text-white w-6 h-6" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Fornecedores;
